@import "../../../assets/styles/config";
@import "../../../assets/styles/theme";
@import "../../../assets/styles/buttons";

.carousel-display {
    .carousel-container {
        overflow: hidden;
        position: relative;
        height: 250px;

        .product-carousel_nav {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: #939393;
            width: 30px;
            height: 50px;
            top: calc(50% - 25px);
            opacity: 0.5;
            z-index: 1;
            font-size: 1rem;
            &.nav-left {
                border-radius: 0 10px 10px 0;
                left: 0;
            }
            &.nav-right {
                border-radius: 10px 0 0 10px;
                right: 0;
            }
            &:hover:not(:disabled) {
                opacity: 0.8;
            }
            &:disabled { opacity: 0; }
        }
        .carousel-slider {
            display: flex;
            justify-content: space-between;
            min-width: 100%;
            height: 100%;
            padding: 0;
            position: absolute;
            transition: all 0.5s;

            .product {
                width: 200px;
                background-color: $product-carousel-bg;
                background-clip: border-box;
                border-radius: 0;
                margin: 0 1px;
        
                .product-header {
                    order: 2;
                    display: flex;
                    flex-direction: column;
                    align-items: space-between;
                    justify-content: center;
                    padding: 0.5rem 0.3rem;
                    .product-thumbnail,
                    .product-expand-button,
                    .product-viewing-progress { 
                        display: none; 
                    }
                    .product-title {
                        font-size: 0.8rem;
                        text-align: center;
                        margin: 0;
                        padding: 0 0.3rem;
                    }
                }
                .product-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 1rem 0.25rem;
                    order: 1;
                    flex: 2;

                    .product-description { display: none; }
                    .product-content {
                        flex: 2;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .product-gallery {
                            height: 100%;
                            .product-gallery_nav,
                            .product-gallery-anchors
                            { display: none; }
                        }
                    }
                    .product-video-container {
                        width: 100%;
                    }
                }
                .product-footer {
                    order: 3;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0.1rem 0.1rem;
                    background-color: $product-carousel-footer-bg;
                    border-top: 1px solid rgba(8, 16, 43, 0.125);
                    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
                    min-height: 90px;
                    .payment-controls {
                        padding: 0.25rem 0;
                        .product-price_container{
                            display: flex;
                            justify-content: space-evenly;
                            padding: 0 0.25rem;
                            .product-price {
                                font-size: 18px;
                                position: relative;
                                .price-symbol { font-size: 60%; }
                                .price-whole {
                                    line-height: 18px;
                                    margin: 0 0.25rem;
                                }
                                .price-fraction { font-size: 60%; }
                                &.strikethrough{
                                    width: fit-content;
                                    padding: 0 0.25rem;
                                    font-size: 90%;
                                    color: $dark-gray;
                                    opacity: 0.3;
                                }
                            }
                        }
                        .payment-controls_qtd-selector {
                            margin-bottom: 0.25rem;
                            height: 20px;
                            input {
                                height: 100%;
                            }
                            .add-to-cart-input-container {
                                &::before { display: none; }
                                input { text-align: center; }
                            }
                        }
                        .payment-controls_buttons-container {
                            display: flex;
                            justify-content: space-evenly;
                            margin: 0;
                            .btn-payment {
                                span { display: none; }
                                margin: 0 0.25rem;
                                width: 70px;
                            }
                        }
                    }
                    .product-button-links {
                        width: 100%;
                        text-align: center;
                    }
            }
            }
        }
    }
}