@import '../../../assets/styles/config';

.order-items {
    border-right: none;
    .order-item {
        border-top: solid 1px $border-color;
        background-color: $card-bg;

        .order-item-thumb {
            img {
                max-height: 150px;
                width: auto;
                aspect-ratio: auto 100 / 100;
            }
        }
        .order-item-details {
            .order-item-name {
            }
            .order-item-description {
            }
            .order-item-qtd-select {
                max-width: 150px;
                cursor: pointer;
                box-shadow: 2px 2px 3px 0px rgb(0 0 0 / 40%);
            }
            .order-item-qtd-input {
                max-width: 150px;
            }

            .order-item-shipping-info {
                color: $primary;
                font-size: 0.6rem;
            }
        }
        .order-item-price {
        }
    }
}

/* lg */
@media (min-width: 992px) {
}
/* md */
@media (min-width: 768px) {
}
/* sm */
@media (min-width: 576px) {
}
/* xs */
@media (max-width: 576px) {
}
