
.plans-groups-table {
    > thead {
        tr {
            background-color: #ffff;
        }
    }
    .plans-group-name {
        text-align: center;
        font-size: 1.3rem;
        width: 100%;
    }
}