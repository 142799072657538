
.cookie-alert {
    position: fixed;
    bottom: 2px;
    left: 1%;
    width: 98%;
    min-height: 50px;
    background-color: #020202e0;
    color: #f2f2f2;
    text-align: center;
    font-size: 12px;
    padding: 0.5rem 1rem;
    z-index: 99999;
}