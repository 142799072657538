@import '../../../../../assets/styles/config';

.dynamic-section-editor {
    .dynamic-section-tools {
        background-color: $primary;
        border-bottom: solid 4px $dark-blue;
        padding: 0.5rem 0.25rem;
        button {
            border-radius: 0.25rem;
        }
    }
}

@mixin dynamicContainer {
    position: relative;
    border: solid 1px transparent;
    box-sizing: border-box;
    border: dashed 1px $primary;
    min-height: 30px;

    .content-editor-change-button {
        position: absolute;
        display: none;
        right: 0.25em;
        top: 0.25rem;
        font-size: 0.7rem;
        z-index: 1;
    }

    &::before {
        display: block;
        background-color: #f2f2f2ee;
        border-radius: 0.5rem;
        position: absolute;
        top: 0;
        font-weight: normal;
        z-index: 1;
        padding: 0.25rem 5px;
    }

    &:hover {
        background-color: #d0b08d1f;
        .content-editor-change-button {
            display: block;
        }
        &::before {
            content: attr(data-type);
            left: 0;
            font-size: 10px;
        }
    }
    &.changing {
        border: solid 1px $primary;
        &::after,
        &::before {
            display: none;
        }
    }

    //tools
    .rsw-toolbar,
    .toolbar {
        flex-wrap: wrap;
        background-color: $light-blue;
        color: $white;
        border-radius: 0.25rem 0.25rem 0 0;
        padding: 0 0.25rem;
        select,
        input[type='text'] {
            padding: 0.25rem;
            height: 25px;
            font-size: 10px;
            min-width: 150px;
            border: none;
        }
        button:not(.btn) {
            border-radius: 0.25rem;
        }
    }
    .rsw-separator {
        border-color: #595959;
    }
    button.rsw-btn:not(.btn) {
        color: $white;
        border-radius: 5px;
        &:hover {
            background-color: $dark-blue;
            color: #f2f2f2;
        }
    }

    .columns-size-selector {
        color: $white;
        .size-selector {
            font-size: 9px;
            .sizes-container {
                padding: 0;
                border: solid 1px $white;
                border-radius: 0.25rem;
                span {
                    padding: 0.15rem;
                    border-radius: 0.15rem;
                    cursor: pointer;
                    &:hover,
                    &.selected {
                        background-color: $btn-primary-hover-bg;
                        color: $btn-primary-color;
                    }
                }
            }
        }
    }
}

.dynamic-content-editor {
    @include dynamicContainer;

    .html-editor-tools {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }

    .field-editor {
        position: relative;
        border: solid 1px transparent;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .field-editor-toolbar {
            background-color: $light-blue;
            color: $white;
            opacity: 0.5;
            width: 100%;
            padding: 0.25rem 0;
            .btn-change-field {
                border-radius: 0.25rem;
                font-size: 10px;
            }
        }
        &.is-changing:hover {
            border: dashed 1px $primary;
            .field-editor-toolbar {
                opacity: 1;
            }
        }
    }
    .plan-editor {
        position: relative;
        border: solid 1px transparent;
        box-sizing: border-box;
        cursor: pointer;
        .plan-editor-toolbar {
            background-color: #d0b08d;
            opacity: 0.5;
            width: 100%;
            padding: 0.25rem 0;
            .btn-change-field {
                border-radius: 0.25rem;
                font-size: 10px;
            }
        }
        &.is-changing:hover {
            border: dashed 1px $primary;
            .plan-editor-toolbar {
                opacity: 1;
            }
        }
    }
}

.dynamic-content {
    @include dynamicContainer;
}

.dynamic-product-display {
    @include dynamicContainer;
}
