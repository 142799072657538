@import '../../../../assets/styles/config';

.product-image-gallery {
    .images-container {
        .image {
            position: relative;
            .delete-image {
                position: absolute;
                right: 0px;
                top: -2px;
                width: 20px;
                height: 20px;
                background-color: $dark-red;
                color: $white;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}
