@import 'app';

.auth-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-width: 350px;

    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea,
    select {
        color: $auth-input-color;
        background-color: $auth-input-bg;
        border-color: $auth-input-border;
    }

    .logo {
        width: 100%;
        max-width: 200px;
        border-radius: 5px;
    }

    .auth-container {
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0px 0px 40px 20px rgb(0 0 0 / 10%);

        > div {
            padding: 20px;
        }

        .auth-form-container {
            transition: 0.5s;
            background-color: $auth-form-container-bg;
            color: $auth-form-container-color;
            &.hiden {
                width: 0;
                overflow: hidden;
            }
            .message-success,
            .message-error {
                background-color: #fffefd;
                padding: 10px;
                text-align: center;
                border-radius: 10px;
            }
            .message-success {
                color: #067a3f;
                border: dashed 2px #067a3f;
            }
            .message-error {
                color: #e72e2e;
                border: dashed 2px #e72e2e;
            }

            .text-error {
                background-color: #e72e2e;
                color: #fffefd !important;
                margin: 0 10px;
                padding: 2px 4px;
                position: relative;
                display: inline-block;
                border-radius: 0.25rem;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: -0.25rem;
                    left: 0.5em;
                    border: 0.25rem solid transparent;
                    border-top: none;
                    border-bottom-color: #e72e2e;
                }
            }

            .tip-message {
                background-color: $primary;
                border-radius: 6px;
                font-size: 0.8rem;
                padding: 10px;
                color: #ffffff;
                position: relative;
                box-shadow:
                    0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
                    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    bottom: -0.75rem;
                    left: 1.5em; // offset should move with padding of parent
                    border: 0.75rem solid transparent;
                    border-bottom: none;
                    border-top-color: $primary;
                    filter: drop-shadow(0 0.19rem 0.1rem rgba(0, 0, 0, 0.3));
                }
            }
        }
        .auth-content-container {
            min-height: 200px;
            color: #fffefd;
            > div {
                width: 100%;
                height: 100%;
                background-color: #00000045;
                border-radius: 0.25rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1.3rem;
            }
        }
    }
}
.auth-footer {
    background-color: $auth-footer-bg;
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
    .auth-screen {
        .auth-container {
            flex-direction: column-reverse;
            .auth-content-container {
                min-height: 200px;
            }
        }
    }
}
