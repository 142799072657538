@import '../../../../assets/styles/config';

.notification {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    &:hover,
    &.new {
        background-color: $secondary;
        color: #595959;
    }
    .notification-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        .datetime {
            font-size: 0.6rem;
            color: $white;
            label {
                background-color: $primary;
                padding: 0.1rem 0.5rem;
                border-radius: 0.25rem;
            }
        }
    }
    &.priority-high {
        .notification-content {
            .datetime label {
                background-color: #fdb8ae;
            }
        }
    }
    &.priority-low {
        .notification-content {
            .datetime label {
                background-color: #6587a540;
            }
        }
    }

    * {
        margin: 0;
        padding: 0;
    }
}
