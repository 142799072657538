@import '../../../assets/styles/config';
@import '../../../assets/styles/theme';
@import '../../../assets/styles/buttons';

.product-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .product {
        transition: all 0.5s;
        width: 100%;
        max-width: 1200px;
        justify-self: center;

        position: relative;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        background-clip: border-box;
        border-radius: 0.25rem;
        overflow: hidden;
        margin-bottom: 0.25rem;

        .product-gallery {
            background-color: $product-card-bg;
        }

        .product-header {
            .product-thumbnail {
                display: none;
            }
            .product-title {
                color: $product-course-title-color;
                text-align: center;
                padding: 0.5rem 0.3rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                a {
                    width: 100%;
                    font-size: 1.5em;
                    text-decoration: none;
                    color: $primary;
                }
                .product-viewing-progress {
                    display: none;
                }
            }
            .product-supplier {
                font-weight: bold;
                span {
                    font-weight: normal;
                    padding-left: 0.25rem;
                }
            }
            .product-expand-button {
                display: none;
            }
        }
        .product-body {
            display: flex;
            flex-flow: row-reverse;
            align-items: center;
            justify-content: space-between;
            transition: all 0.5s linear;
            overflow: hidden;
            width: 100%;
            padding: 0.5rem;

            .product-description,
            .product-content {
                width: 100%;
                min-width: 270px;
            }
            .product-description {
                padding: 0.3rem;
                display: flex;
                flex-direction: column;
                height: 100%;
                .text-description {
                    font-weight: 600;
                }
                .text-details {
                    flex: 2;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #888;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
                .product-price_container {
                    align-items: center;
                    width: 100%;
                    .product-price {
                        position: relative;
                        margin: 0.5rem;
                        font-size: 1.5rem;
                        .price-symbol {
                            font-size: 60%;
                        }
                        .price-whole {
                        }
                        .price-fraction {
                            font-size: 60%;
                        }
                        &.strikethrough {
                            color: $dark-gray;
                            opacity: 0.3;
                            font-size: 1rem;
                        }
                    }
                }
                .payment-controls {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .payment-controls_buttons-container {
                        display: flex;
                        justify-content: space-evenly;
                        padding: 0.25rem;
                        .btn-payment {
                            flex: 0 1 40%;
                            width: auto;
                            margin: 0.5px;
                        }
                    }
                    .payment-controls_qtd-selector {
                        max-width: 300px;
                        align-self: center;
                    }
                }
                .product-button-link {
                    width: 100%;
                    text-align: center;
                }
            }
            .product-content {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 0.25rem;
                .private-content {
                    height: 100%;
                    width: 100%;
                }
                .product-video-container {
                    width: 80%;
                }
                .product-gallery {
                    min-height: 300px;
                }
                .product-video-player {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    flex: 2;
                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .product-footer {
            background-color: #ffdfb9d1;
            border-radius: 0 0 0.25rem 0.25rem;
            display: none;
            .product-button-link {
                display: none;
            }
        }
    }
}

@media (max-width: 420px) {
    .product-page {
        .product {
            .payment-controls_buttons-container {
                flex-wrap: wrap !important;
                .btn-payment {
                    margin-bottom: 0.25rem !important;
                    flex: 0 1 100% !important;
                }
            }
        }
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 768px) {
    .product-page {
        .product {
            .product-body {
                flex-flow: column-reverse;
                .product-content,
                .product-description {
                    width: 100%;
                }
            }
        }
    }
}
