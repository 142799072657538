$sidebar-size-open: 254px;
$sidebar-size-closed: 58px;

.sidebar {
    $sidebar-size: $sidebar-size-open;

    min-width: $sidebar-size;
    max-width: $sidebar-size;
    background: $sidebar-bg;
    margin-left: 5px;
    transition: all 0.5s;
    align-self: stretch;
    padding-top: 0;
    font-size: 1.5rem;
    display: table-cell;
    display: flex;
    border-radius: 10px;
    z-index: 110;
    span {
        font-size: 0.8rem;
        transition: all 0.2s;
        color: inherit;
        opacity: 1;
        padding: 0.3rem;
        left: 0;
    }

    &.is-closed {
        $sidebar-size: $sidebar-size-closed;

        min-width: $sidebar-size;
        max-width: $sidebar-size;

        .side-menu {
            .nav-item {
                width: 100%;
                .nav-link {
                    justify-content: center;
                    .side-menu-alt-content {
                        display: flex;
                    }
                    .side-menu-label {
                        transition: all 0.5s;
                        position: absolute;
                        left: -300px;
                        min-width: 200px;
                        color: $white;
                        opacity: 0;
                        border-radius: 0 6px 6px 0;
                    }
                    .side-menu-icon {
                        display: none;
                    }
                    &:hover .side-menu-label {
                        background-color: #08102bc2;
                    }
                }
                .side-menu-group-container {
                    padding: 0 0 2px 0;
                    border-bottom: solid 2px $sidebar-border-color;
                }
            }
        }
    }
    .side-menu {
        width: 100%;
        padding-top: 5px;

        .btn-toggler,
        .btn-toggler:focus,
        .btn-toggler:active,
        .btn-toggler:hover {
            background-color: $sidebar-toggler-bg;
            color: $sidebar-toggler-color;
            border-radius: 2px;
            opacity: 0.5;
            flex: 1;
            width: 40px;
            height: 25px;
            margin: 0 0 2px 9px;
            &:focus {
                box-shadow: none;
                outline: none;
            }
            &:hover:not(:disabled) {
                opacity: 1;
            }
        }

        .nav-sep {
            background-color: $sidebar-sep-bg;
            color: $sidebar-sep-color;
            margin: 2px 6px;
            min-height: 2px;
        }

        .nav-item {
            border: 0;
            padding: 2px 6px;

            .nav-link {
                background-color: $sidebar-item-bg;
                color: $sidebar-item-color;
                border: none;
                border-radius: 4px;
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: stretch;
                padding: 0.25rem 0;
                margin: 0;
                transition: 0.15s;
                position: relative;

                cursor: pointer;

                .side-menu-alt-content {
                    display: none;
                    flex-direction: column;
                    .side-menu-icon {
                        height: 100%;
                        min-width: 32px;
                        font-size: 14px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .side-menu-short-label {
                        font-size: 8px;
                        padding: 0;
                        text-align: center;
                        white-space: nowrap;
                    }
                }

                &.active,
                &:hover:not(:disabled) {
                    background-color: $sidebar-item-active-bg;
                    color: $sidebar-item-active-color;
                }
                &:hover {
                    .side-menu-label {
                        left: $sidebar-size;
                        opacity: 1;
                    }
                }
                &.disabled {
                    opacity: 0.5;
                }
            }
            .side-menu-group-container {
                padding-left: 10px;
                .nav-link {
                    margin: 2px 0;
                }
            }
        }
    }
    .user-profile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0.25rem;
        .user-profile-icon {
            background-color: #ffffff;
            overflow: hidden;
            border-radius: 50%;
            aspect-ratio: 100/100;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 2px $sidebar-border-color;
            margin: 0;
        }
        .user-profile-name {
            width: 100%;
            font-size: 0.8rem;
            text-align: center;
        }
        .user-pendencies {
            font-size: 0.7rem;
            color: #ffffff;
            border: solid 1px #bd403183;
            background-color: #bd403183;
            padding: 0.1rem 0.5rem;
            border-radius: 8px;
        }
    }
}

/* md */
@media (max-width: 767px) {
    section {
        padding-left: 53px;
        .sidebar {
            position: absolute;
            left: 5px;
            .btn-toggler {
                display: flex !important;
            }
        }
        &.sidebar-open {
            .sidebar {
                width: calc(100% - 20px);
                max-width: calc(100% - 20px);
                min-width: calc(100% - 20px);
            }
        }
    }
}
