@import '../../assets/styles/config';

.formik-dev-tools {
    border: dashed 1px $primary;
    padding: 0.25rem;
    h4 {
        font-size: 12px;
        font-weight: bold;
        font-family: monospace;
        color: #595959;
        span {
            font-size: 10px;
        }
    }
    .title {
        background-color: #d0b08d;
        text-align: center;
        padding: 0.25rem;
    }

    pre {
        background-color: #f6f6f6;
        border: solid 1px #c0c0c0;
        font-family: monospace;
        padding: 0.25rem;
    }
}
