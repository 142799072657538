

@import "../../../../../assets/styles/config";

.payment-summary {
    background-color: $cart-summary-bg;

    .payment-values {
        border-bottom: none;
        border-right: solid 1px $cart-summary-border;
    }
    .payment-shipping {
        border-bottom: none;
        border-right: solid 1px $cart-summary-border;
        .payment-address {
            .payment-address-title {
                font-weight: 600;
            }
            .payment-address-details {
                background-color: $cart-address-bg;
                border: solid 1px $cart-address-border;
                padding: 0.4rem;
            }
            .payment-address-change {
                color: $dark-blue;
                font-weight: 500;
                cursor: pointer;
            }
        }    
    }
    .payment-controls {

    }
}
