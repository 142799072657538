@import '../../../../../assets/styles/config';

.subscription-plan-details {
    background-color: $checkout-form-bg;
    border-radius: 10px;

    .plan-details-header {
        background-color: $checkout-form-method-bg;
        border-radius: 10px;
    }

    .subscription-plan-price {
        background-color: $checkout-form-method-bg;
        border-radius: 10px;
        .plan-price-description {
            border-top-width: 1px;
            border-top-style: solid;
            border-top-color: inherit;
        }
    }
}
