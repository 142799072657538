@import '../PlanDisplay.scss';

.default-plan-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0.5rem;
    .plan {
        max-width: 350px;
        width: 350px;
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.45);
        background-color: $plan-default-bg;

        .plan-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0.5rem 0.5rem 0.5rem;
            position: relative;
            .description-container {
                background-color: gray;
                display: block;
                height: 25%;
                width: 100%;
                position: absolute;
                z-index: 0;
                top: 20%;
                left: 0;
                span {
                    display: none;
                }
            }
            .title-container {
                width: 290px;
                text-align: center;
                background-color: gray;
                color: #f2f2f2;
                font-weight: 700;
                letter-spacing: 0.25rem;
                position: relative;
                padding: 25px 0 25px 0;
                margin-bottom: 50px;
                font-size: 1.7rem;
                line-height: 2rem;
                z-index: 1;
                span {
                    z-index: 2;
                    position: relative;
                }
                &:before,
                &:after {
                    background-color: inherit;
                    content: ' ';
                    display: block;
                    height: 50%;
                    width: 50%;
                    position: absolute;
                    z-index: 0;
                    top: 51%;
                    border-bottom-style: solid;
                    border-bottom-width: 4px;
                    border-color: inherit;
                }
                &:before {
                    transform: skewy(15deg); /* angle you want */
                    transform-origin: bottom left;
                    left: 0;
                }
                &:after {
                    transform: skewy(-15deg); /* angle you want */
                    transform-origin: bottom right;
                    left: 50%;
                }
            }
        }
        .plan-body {
            padding: 0.5rem 1rem;
            font-size: 0.9rem;
        }
        .plan-footer {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 0.5rem;
            .subscription-button {
                flex: 1;
                margin: 0.25rem 0;
                min-height: 45px;
                .subscription-button-title {
                    flex: 1;
                    text-align: left;
                }
                .subscription-button-price {
                    .price-symbol {
                        font-size: 60%;
                    }
                    .price-whole {
                        padding: 0 0.25rem;
                    }
                    .price-fraction {
                        font-size: 60%;
                    }
                }
            }
            .subscripted-advice {
                padding: 0.5rem;
                border: dashed 1px $badge-green-bg;
                color: $badge-green-bg;
                border-radius: 0.5rem;
            }
        }
    }
}
@media (max-width: 375px) {
    .default-plan-display {
        .plan {
            max-width: 260px;
            width: 260px;

            .plan-header {
                .title-container {
                    width: 230px;
                }
            }
        }
    }
}
