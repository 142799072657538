
.header-column {
    cursor: pointer;

    svg {
        margin-left: 0.5rem;
        opacity: 0.3;
        font-size: inherit;
    }
    &.active svg { opacity: 1; }
}

.no-space-before-icon {
    white-space: nowrap;
}
