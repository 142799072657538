@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .downloading-spinner {
    /*
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    */
    animation: spinner 1.5s linear infinite;
  }