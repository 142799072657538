footer {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    padding: 0.5rem;
    background-color: $footer-bg;
    color: $footer-color;
    font-size: 0.8rem;
    .copy-right {
    }
}
.footer-links {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    a {
        margin: 0 0.25rem;
        color: $footer-links-color;
    }
}
