.suggest-with-trap {
  .dropdown-menu {
    width: 100%;
    max-height: 130px;
    overflow: auto;
  }
  .react-autosuggest__suggestions-container:empty {
    list-style: none;
    padding: 0;
  }
  .react-autosuggest__suggestions-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .dropdown-item {
    cursor: pointer;
    display: block;
    background-color: #ffffff !important;
    border-radius: 0;
    font-weight: normal;
    font-size: 0.8rem;
    &:hover, &:active {
      background-color: #f2f2f2 !important;
      color: unset;
    }
  }
}