@import './config';
@import './theme';

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $regular-primary;
    color: $white;
    border-radius: 20px;
    border: none;
    line-height: 1;
    outline-style: none;
    box-shadow: none;
    flex-shrink: 1;
    outline: none;
    transition: all 0.3s;
    &.btn:active {
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.5;
    }
    /* Primary */
    &.btn-primary {
        background-color: $btn-primary-bg;
        color: $btn-primary-color;
        &:hover:not(:disabled) {
            background-color: $btn-primary-hover-bg;
            color: $white;
        }
    }
    &.btn-primary-outline {
        background-color: $btn-primary-color;
        border: solid 2px $btn-primary-bg;
        color: $btn-primary-bg;
        &:hover:not(:disabled) {
            background-color: $btn-primary-bg;
            color: $btn-primary-color;
        }
    }

    /* Secondary */
    &.btn-secondary {
        background-color: $btn-secondary-bg;
        color: $btn-secondary-color;
        &:hover:not(:disabled) {
            background-color: $btn-secondary-hover-bg;
            color: $btn-secondary-hover-color;
        }
    }
    &.btn-secondary-outline {
        background-color: $white;
        border: solid 2px $btn-secondary-bg;
        color: $btn-secondary-color;
        &:hover:not(:disabled) {
            background-color: $btn-secondary-hover-bg;
            color: $btn-secondary-hover-color;
            border: solid 2px $btn-secondary-hover-bg;
        }
    }
    /* Success / Green */
    &.btn-success,
    &.btn-green {
        background-color: $btn-green-bg;
        color: $btn-green-color;
        &:hover:not(:disabled) {
            background-color: $btn-green-hover-bg;
            color: $btn-green-hover-color;
        }
    }
    &.btn-success-outline,
    &.btn-green-outline {
        background-color: $btn-green-color;
        border: solid 2px $btn-green-bg;
        color: $btn-green-bg;
        &:hover:not(:disabled) {
            background-color: $btn-green-hover-bg;
            color: $btn-green-hover-color;
            border: solid 2px $btn-green-hover-bg;
        }
    }

    /* Danger / Red */
    &.btn-danger,
    &.btn-red {
        background-color: $btn-red-bg;
        color: $btn-red-color;
        &:hover:not(:disabled) {
            background-color: $btn-red-hover-bg;
            color: $btn-red-hover-color;
        }
    }
    &.btn-danger-outline,
    &.btn-red-outline {
        background-color: $btn-red-color;
        border: solid 2px $btn-red-bg;
        color: $btn-red-bg;
        &:hover:not(:disabled) {
            background-color: $btn-red-hover-bg;
            color: $btn-red-hover-color;
            border: solid 2px $btn-red-hover-bg;
        }
    }

    /* Warning / Yellow */
    &.btn-warning,
    &.btn-yellow {
        background-color: $btn-yellow-bg;
        color: $btn-yellow-color;
        &:hover:not(:disabled) {
            background-color: $btn-yellow-hover-bg;
            color: $btn-yellow-hover-color;
        }
    }
    &.btn-warning-outline,
    &.btn-yellow-outline {
        background-color: $btn-yellow-color;
        border: solid 2px $btn-yellow-bg;
        color: $btn-yellow-bg;
        &:hover:not(:disabled) {
            background-color: $btn-yellow-hover-bg;
            color: $btn-yellow-hover-color;
            border: solid 2px $btn-yellow-hover-bg;
        }
    }

    /* Warning / Yellow */
    &.btn-info,
    &.btn-blue {
        background-color: $btn-blue-bg;
        color: $btn-blue-color;
        &:hover:not(:disabled) {
            background-color: $btn-blue-hover-bg;
            color: $btn-blue-hover-color;
        }
    }
    &.btn-info-outline,
    &.btn-blue-outline {
        background-color: $btn-blue-color;
        border: solid 2px $btn-blue-bg;
        color: $btn-blue-bg;
        &:hover:not(:disabled) {
            background-color: $btn-blue-hover-bg;
            color: $btn-blue-hover-color;
            border: solid 2px $btn-blue-hover-bg;
        }
    }

    /* Warning / Yellow */
    &.btn-gray {
        background-color: $btn-gray-bg;
        color: $btn-gray-color;
        &:hover:not(:disabled) {
            background-color: $btn-gray-hover-bg;
            color: $btn-gray-hover-color;
        }
    }
    &.btn-gray-outline {
        background-color: $btn-gray-color;
        border: solid 2px $btn-gray-bg;
        color: $btn-gray-bg;
        &:hover:not(:disabled) {
            background-color: $btn-gray-hover-bg;
            color: $btn-gray-hover-color;
            border: solid 2px $btn-gray-hover-bg;
        }
    }
}

.btn-badge {
    position: relative;
    .btn-counter-badge {
        position: absolute;
        font-size: 0.5rem;
        line-height: 0.5rem;
        min-height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        bottom: 0;
        right: 0;
        padding: 3px 6px 1px 6px;
        opacity: 0.7;
    }
}

/**
 * Button Sizes
 */
.btn-large {
    min-width: 370px;
    min-height: 47px;
    font-size: 1.1rem;
    flex-shrink: 1;
    display: flex;
}
.btn-medium {
    width: 170px;
    min-height: 37px;
    font-size: 0.9rem;
}
.btn-small {
    min-height: 29px;
    font-size: 0.7rem;
}
.btn-icon-small {
    min-width: unset;
    min-height: unset;
    width: 47px;
    height: 47px;
    border-radius: 24px;
    font-size: 1.2rem;
}

.btn-icon-left {
    svg {
        margin: 0.25rem 0;
    }
    span {
        margin-left: 0.25rem;
        text-align: center;
    }
}
/**
 * Custom Buttons
 */
/* Add product to cart */
.btn-add-to-cart {
    background-color: $btn-add-to-cart-bg;
    border: solid 2px $btn-add-to-cart-border;
    color: $btn-add-to-cart-color;
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        background-color: $btn-add-to-cart-hover-bg;
    }
}
/* Remove product from cart */
.btn-remove-from-cart {
    background-color: $btn-remove-from-cart-bg;
    border: solid 2px $btn-remove-from-cart-border;
    color: $btn-remove-from-cart-color;
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        background-color: $btn-remove-from-cart-border;
    }
}
/* Go to direct purchase */
.btn-buy-now {
    background-color: $btn-buy-now-bg !important;
    border: solid 2px $btn-buy-now-border !important;
    color: $btn-buy-now-color;
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        background-color: $btn-buy-now-border;
    }
}
/* Go to cart page floating button */
.cart-floating-button {
    position: fixed;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    right: 0;
    bottom: 2rem;
    z-index: 1;
    font-size: 1rem;
    box-shadow: 0px 2px 4px 0px $outer-shadow-color;
    a,
    a:hover,
    a:visited {
        background-color: $btn-cart-floating-bg;
        color: $btn-cart-floating-color;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0;
    }
}

/* Go to checkout page */
.btn-gotopayment {
    background-image: $btn-gotopayment-bg;
    color: $btn-gotopayment-color;
    font-size: 1.5rem;
    font-weight: 600;
    border: solid 2px $btn-gotopayment-border;
    min-height: 5vw;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    text-align: center;
    text-decoration: none;
    &:hover {
        color: $btn-gotopayment-color;
        background-image: $btn-gotopayment-hover-bg;
        border-color: $btn-gotopayment-hover-border;
    }
    &:focus {
        color: $btn-gotopayment-color;
    }
    &__small {
        font-size: 1rem;
        min-height: unset;
        line-height: 1rem;
        padding: 0.3rem;
    }
}
