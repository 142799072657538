@import '../../../assets/styles/config';
@import '../../../assets/styles/theme';
@import '../../../assets/styles/buttons';

.cards-display {
    min-height: 300px;
    .product {
        min-width: 0;
        background-color: $product-card-bg;
        background-clip: border-box;
        border-radius: 0.25rem;
        min-width: 300px;
        width: 350px;

        .product-supplier {
            text-align: left;
            width: 100%;
            padding: 0 0.3rem;
            span {
                padding: 0 0.25rem;
            }
        }

        .product-header {
            display: flex;
            flex-direction: column;
            align-items: space-between;
            justify-content: center;
            padding: 0.5rem 0.3rem;
            .product-thumbnail,
            .product-expand-button,
            .product-supplier,
            .product-viewing-progress {
                display: none;
            }
            .product-title {
                text-align: center;
                margin: 0;
            }
        }
        .product-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0.25rem 1rem;
            flex: 2;
            .product-description {
                text-align: center;
                margin: 0;
                padding: 0.3rem;
                .text-description {
                    margin: 0;
                }
                .text-details {
                    display: none;
                }
                .payment-controls {
                    display: none;
                }
                .product-button-links {
                    display: none;
                }
            }
            .product-content {
                flex: 2;
                display: flex;
                flex-direction: column;
                .private-content {
                    flex: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .product-video {
            }
            .product-gallery {
            }
        }

        .product-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0.5rem 1rem;
            background-color: $product-card-footer-bg;
            border-top: 1px solid rgba(8, 16, 43, 0.125);
            border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
            .payment-controls {
                display: flex;
                flex-direction: column;
                .product-price {
                    position: relative;
                    .price-symbol {
                        font-size: 60%;
                    }
                    .price-whole {
                    }
                    .price-fraction {
                        font-size: 60%;
                    }
                    &.strikethrough {
                        margin: 0 0.5rem;
                        position: relative;
                        font-size: 90%;
                        color: $dark-gray;
                        opacity: 0.5;
                        &::after {
                            top: 40%;
                        }
                    }
                }
                .payment-controls_buttons-container {
                    display: flex;
                    justify-content: space-evenly;
                    width: 100%;
                    padding: 0.25rem 0;
                    .btn-payment {
                        flex: 1;
                        margin: 0 0.25rem;
                    }
                }
            }
            .product-button-links {
                display: flex;
                justify-content: center;
            }
        }
    }
}
