@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@import './config';
@import './theme';
@import 'node_modules/bootstrap/scss/bootstrap';

@import './buttons';
@import './badges';
@import './modal';
@import './tables';

@mixin outer-shadow {
    -webkit-box-shadow: 0px 2px 4px 0px $outer-shadow-color;
    -moz-box-shadow: 0px 2px 4px 0px $outer-shadow-color;
    box-shadow: 0px 2px 4px 0px $outer-shadow-color;
}
@mixin clear-outer-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
@mixin inner-shadow {
    -webkit-box-shadow: inset 2px 2px 2px 0px $outer-shadow-color;
    -moz-box-shadow: inset 2px 2px 2px 0px $outer-shadow-color;
    box-shadow: inset 2px 2px 2px 0px $outer-shadow-color;
}
@mixin clear-inner-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.outer-shadow {
    @include outer-shadow;
}
.inner-shadow {
    @include inner-shadow;
}

.ai-center {
    align-items: center;
}
.jc-center {
    justify-content: center;
}
.center-all {
    align-items: center;
    justify-content: center;
}

.bg-dark-brown {
    background-color: $dark-brown !important;
    color: $light-brown-text !important;
}

.border {
    border: solid 1px $border-color !important;
}
.border-top {
    border-top: solid 1px $border-color !important;
}
.border-left {
    border-left: solid 1px $border-color !important;
}
.border-right {
    border-right: solid 1px $border-color !important;
}
.border-bottom {
    border-bottom: solid 1px $border-color !important;
}
.border-radius {
    border-radius: 0.3rem !important;
}
.br-2 {
    border-radius: 2px;
}
.br-3 {
    border-radius: 3px;
}
.br-4 {
    border-radius: 4px;
}
.br-5 {
    border-radius: 5px;
}
.br-6 {
    border-radius: 6px;
}
.br-7 {
    border-radius: 7px;
}
.br-8 {
    border-radius: 8px;
}
.br-9 {
    border-radius: 9px;
}
.br-10 {
    border-radius: 10px;
}
.br-15 {
    border-radius: 15px;
}
.br-20 {
    border-radius: 20px;
}

.no-border {
    border: none !important;
}

//Classes de tamanho de texto
.text-big {
    font-size: 22px !important;
    line-height: 24px;
}
.text-large {
    font-size: 18px !important;
    line-height: 20px;
}
.text-medium {
    font-size: 14px !important;
    line-height: 16px;
}
.text-small {
    font-size: 12px !important;
    line-height: 14px;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.width-min {
    width: min-content;
}
.width-fit {
    width: fit-content;
}

.strikethrough {
    &::after {
        border-top: 1px solid;
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        left: 0;
    }
}

html,
body,
#root,
#main-wrapper {
    min-height: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
.App {
    height: 100%;
}
section {
    display: flex;
    min-height: calc(100% - 202px);
    position: relative;
    transition: all 0.5s;
    padding-bottom: 10px;
}

body {
    background-color: $body-bg;
    color: $body-color;
}

*:focus {
    outline: none;
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: unset;
}

h1 {
    font-size: 35px;
}
h2 {
    font-size: 25px;
}
h3 {
    font-size: 20px;
}
h4 {
    font-size: 18px;
}

a,
a:hover,
a:focus {
    cursor: pointer;
    color: $dark-blue;
    transition: all 0.3s;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='datetime'],
input[type='time'],
input[type='date'],
.field-value,
textarea,
select {
    color: $input-color;
    border-radius: 0.5rem;
    padding: 0.3rem 0.6rem;
    background-color: $input-bg;
    height: 37px;
    border: solid 2px $input-border;
    &:disabled {
        opacity: 0.5;
    }
}
.field-value {
    border-color: $input-bg;
}
textarea {
    height: 150px;
    resize: none;
}
pre {
    white-space: pre-wrap;
}

.mr-1 {
    margin-right: 0.25rem !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}
.pl-1 {
    padding-left: 0.25rem !important;
}

.card {
    background-color: $card-bg;
    .card-footer {
        background-color: $card-footer-bg;
        margin-bottom: 10px;
    }
}

@import './sidebar';
@import './navbar';
@import './footer';
@import './forms';

.content-wrapper {
    width: 100%;
    flex-shrink: 1;
    display: table-cell;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;

    .content-title {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .content {
        width: 100%;
        height: 100%;
        max-width: unset;
        border-radius: 0.5rem;
        /*
        border: solid 2px $dark-brown;
        */

        h3 {
            font-size: 1rem;
            font-weight: 600;
        }
        .page-content {
            // padding: 1rem;
        }
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
    .content-wrapper {
        .content {
            .page-content {
                padding: 1rem 0.25rem;
            }
        }
    }
}

.private-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .blocked-product_svg {
        position: absolute;
        opacity: 0.4;
        width: 30% !important;
        height: 30% !important;
    }
    img {
        opacity: 0.7;
        @include outer-shadow;
    }
}
