@import '../../../assets/styles/config';
@import '../../../assets/styles/theme';

.product-gallery {
    height: 250px;
    position: relative;
    /*
    border: solid 1px #6b3b1c1c;
    */
    .product-gallery_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 10%;
        max-width: 40px;
        height: 15%;
        top: 40%;
        opacity: 0.5;
        border: none;
        svg {
            width: 95% !important;
            height: 95% !important;
        }
        &.nav-left {
            border-radius: 0 10% 10% 0;
            left: 0;
        }
        &.nav-right {
            border-radius: 10% 0 0 10%;
            right: 0;
        }
        &:hover:not(:disabled) {
            opacity: 0.8;
        }
        &:disabled {
            opacity: 0;
        }
    }
    .product-gallery-anchors {
        position: absolute;
        display: flex;
        z-index: 1;
        bottom: 0.25rem;
        width: 100%;
        justify-content: center;
        .product-gallery-anchor {
            display: inline-flex;
            width: 12px;
            height: 12px;
            border-radius: 8px;
            opacity: 0.5;
            cursor: pointer;
            background-color: $product-gallery-anchor-bg;
            border: solid 1px $product-gallery-anchor-border;
            margin: 0 0.25rem;
            &.active,
            &:hover {
                opacity: 1;
            }
        }
    }
    .product-gallery_slider-container {
        height: 100%;
        position: relative;
        overflow: hidden;
        .product-gallery_slider {
            display: flex;
            height: 100%;
            transition: all 0.2s;
            .product-gallery_image {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                img {
                    width: 100%;
                }
            }
        }
    }
}
