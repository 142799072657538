@import '../../assets/styles/config';

.password-field-container {
    position: relative;
    display: flex;
    .password-field {
        width: 100%;
        padding-right: 30px;
    }
    .toggle-show-password {
        position: absolute;
        right: 0;
        height: 100%;
        width: 35px;
        color: $auth-input-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.copy-to-clipboard-field-container {
    position: relative;
    display: flex;
    .copy-to-clipboard-field {
        width: 100%;
        padding-right: 30px;
        overflow: hidden;
        border-style: dashed;
    }
    .copy-to-clipboard-icon {
        position: absolute;
        right: 0;
        height: 100%;
        width: 35px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ui-custom-switch {
    input[type='checkbox'] {
        display: none;
    }
    &.disabled {
        opacity: 0.7;
    }
    .ui-cs-switch {
        cursor: pointer;
        position: relative;
        border: solid 1px #4e4e4e;
        background-color: #cacaca;
        width: 30px;
        height: 16px;
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
        box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
        transition: all 0.5s;

        &::before {
            transition: all 0.2s;
            content: '';
            display: inline-flex;
            position: absolute;
            left: 1px;
            top: 1px;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background-color: rgba(0, 0, 0, 0.5);
        }

        &.checked {
            background-color: #28a745;
            &::before {
                left: 14px;
            }
        }
    }
}

.ui-custom-radio {
    cursor: pointer;
    position: relative;
    border: solid 1px #4e4e4e;
    background-color: #cacaca;
    width: 16px;
    height: 16px;
    border-radius: 8px !important;
    -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
    transition: all 0.5s;
    input[type='radio'] {
        display: none;
    }

    &::before {
        transition: all 0.2s;
        display: none;
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &.selected {
        background-color: #28a745;
        &::before {
            display: inline-flex;
        }
    }
}

.ui-selectable-input {
    border: solid 1px #333333;
    border-radius: 0.3rem !important;
    height: 37px;
    background-color: #f2f2f2;
    border: 2px solid #d9d9d9;
    border-radius: 7px;
    .si-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 30px;
        background-color: #e9e9e9;
        cursor: pointer;
        input[type='checkbox'] {
            cursor: pointer;
        }
    }
    .si-label {
        display: flex;
        align-items: center;
        background-color: #e9e9e9;
        padding: 0 0.3rem;
        border-right: solid 1px #d9d9d9;
        label {
            opacity: 0.5;
        }
    }
    .si-input {
        position: relative;
        input {
            background-color: none;
            height: inherit;
            border: none;
            margin: 0;
            opacity: 0.5;
        }
        .si-input-hint {
            position: absolute;
            right: 4px;
            font-size: 0.7rem;
            opacity: 0.5;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    &.si-input-selected {
        .si-label label,
        .si-input input {
            opacity: 1;
        }
    }
}

.label-tip {
    display: inline-flex;
    position: relative;
    .label-tip-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 0.6rem;
        line-height: 0.6rem;
        background-color: #fdfdfd;
        width: 13px;
        height: 13px;
        aspect-ratio: 1/2;
        border-radius: 10px;
    }
    .label-tip-text {
        background-color: #fddac6;
        border: solid 1px #ec5c0ca3;
        transform-origin: bottom left;
        border-radius: 10px;
        padding: 0.5rem;
        font-size: 0.7rem;
        font-weight: normal;
        position: absolute;
        display: none;
        left: 100%;
        width: max-content;
        max-width: 30vw;
        color: #ec5c0c;
        transform: translateY(-100%);
        box-shadow: 0px 5px 4px #00000045;
        z-index: 1;
    }
    &:hover {
        .label-tip-text {
            //            display: block;
        }
    }
}

.quantity-selector {
    button.btn-decrease {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    button.btn-increase {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .quantity-selector-value {
        text-align: center;
        background-color: #ffffff35;
        min-width: 40px;
        padding: 0 0.5rem;
        border: solid 2px $primary;
    }
}
