$primary: #2c536a;
$secondary: #d2cccc;
$black: #08102b;
$white: #ffffff;

/* Texts */
.text-primary {
    color: $primary !important;
}
.text-secondary {
    color: $secondary !important;
}
.text-success {
    color: #0fa759 !important;
}
.text-alert {
    color: #e19406 !important;
}
.text-error {
    color: #bd4031 !important;
}
.text-info {
    color: #305474 !important;
}
.text-red {
    color: #bd4031 !important;
}

.text-black {
    color: $black !important;
}
.text-white {
    color: $white !important;
}

.text-regular-blue {
    color: $regular-blue !important;
}
.text-dark-blue {
    color: $dark-blue !important;
}
.text-light-blue {
    color: $light-blue !important;
}
.text-hover-blue {
    color: $hover-blue !important;
}

.text-dark-green {
    color: $dark-green !important;
}
.text-regular-green {
    color: $regular-green !important;
}
.text-light-green {
    color: $light-green !important;
}
.text-hover-green {
    color: $hover-green !important;
}

.text-dark-yellow {
    color: $dark-yellow !important;
}
.text-regular-yellow {
    color: $regular-yellow !important;
}
.text-light-yellow {
    color: $light-yellow !important;
}
.text-hover-yellow {
    color: $hover-yellow !important;
}

.text-dark-red {
    color: $dark-red !important;
}
.text-regular-red {
    color: $regular-red !important;
}
.text-light-red {
    color: $light-red !important;
}
.text-hover-red {
    color: $hover-red !important;
}

.text-dark-brown {
    color: $dark-brown !important;
}
.text-regular-brown {
    color: $regular-brown !important;
}
.text-light-brown {
    color: $light-red !important;
}
.text-hover-brown {
    color: $hover-red !important;
}

.text-dark-gray {
    color: $dark-gray !important;
}
.text-regular-gray {
    color: $regular-gray !important;
}
.text-light-gray {
    color: $light-gray !important;
}
.text-hover-gray {
    color: $hover-gray !important;
}

.text-dark-orange {
    color: $dark-orange !important;
}
.text-regular-orange {
    color: $regular-orange !important;
}
.text-light-orange {
    color: $light-orange !important;
}
.text-hover-orange {
    color: $hover-orange !important;
}

.text-dark-purple {
    color: $dark-purple !important;
}
.text-regular-purple {
    color: $regular-purple !important;
}
.text-light-purple {
    color: $light-purple !important;
}
.text-hover-purple {
    color: $hover-purple !important;
}

/* Backgrounds */
.bg-primary {
    background-color: $primary !important;
}
.bg-secondary {
    background-color: $secondary !important;
}
.bg-success {
    background-color: #0fa759 !important;
}
.bg-alert {
    background-color: #e19406 !important;
}
.bg-error {
    background-color: #bd4031 !important;
}
.bg-info {
    background-color: #305474 !important;
}

.bg-black {
    background-color: $black !important;
}
.bg-white {
    background-color: $white !important;
}

.bg-regular-blue {
    background-color: $regular-blue !important;
}
.bg-dark-blue {
    background-color: $dark-blue !important;
}
.bg-light-blue {
    background-color: $light-blue !important;
}
.bg-hover-blue {
    background-color: $hover-blue !important;
}

.bg-dark-green {
    background-color: $dark-green !important;
}
.bg-regular-green {
    background-color: $regular-green !important;
}
.bg-light-green {
    background-color: $light-green !important;
}
.bg-hover-green {
    background-color: $hover-green !important;
}

.bg-dark-yellow {
    background-color: $dark-yellow !important;
}
.bg-regular-yellow {
    background-color: $regular-yellow !important;
}
.bg-light-yellow {
    background-color: $light-yellow !important;
}
.bg-hover-yellow {
    background-color: $hover-yellow !important;
}

.bg-dark-red {
    background-color: $dark-red !important;
}
.bg-regular-red {
    background-color: $regular-red !important;
}
.bg-light-red {
    background-color: $light-red !important;
}
.bg-hover-red {
    background-color: $hover-red !important;
}

.bg-dark-brown {
    background-color: $dark-brown !important;
}
.bg-regular-brown {
    background-color: $regular-brown !important;
}
.bg-light-brown {
    background-color: $light-brown !important;
}
.bg-hover-brown {
    background-color: $hover-brown !important;
}

.bg-dark-gray {
    background-color: $dark-gray !important;
}
.bg-regular-gray {
    background-color: $regular-gray !important;
}
.bg-light-gray {
    background-color: $light-gray !important;
}
.bg-hover-gray {
    background-color: $hover-gray !important;
}

.bg-dark-orange {
    background-color: $dark-orange !important;
}
.bg-regular-orange {
    background-color: $regular-orange !important;
}
.bg-light-orange {
    background-color: $light-orange !important;
}
.bg-hover-orange {
    background-color: $hover-orange !important;
}

.bg-dark-purple {
    background-color: $dark-purple !important;
}
.bg-regular-purple {
    background-color: $regular-purple !important;
}
.bg-light-purple {
    background-color: $light-purple !important;
}
.bg-hover-purple {
    background-color: $hover-purple !important;
}

$body-bg: #e0e0e0;
$body-color: #3e698e;
$border-color: #dbd3d3;

/* top header - navbar */
$navbar-bg: #f0e8e8;
$navbar-color: #2c536a;
/* top header - sub-navbar */
$sub-navbar-bg: 'transparent';
$sub-navbar-color: #595959;
$sub-navbar-hover: #3e698e;

/* sidebar */
$sidebar-bg: #f0e8e8;
$sidebar-toggler-bg: #595959;
$sidebar-toggler-color: #f1f1f1;
$sidebar-sep-bg: #2c536a;
$sidebar-sep-color: #f1f1f1;
$sidebar-item-bg: rgba(255, 255, 255, 0.5);
$sidebar-item-color: #2c536a;
$sidebar-border-color: #2c536a;
$sidebar-item-active-bg: #2c536a;
$sidebar-item-active-color: #f1f1f1;

/* form data */
$form-data-title-bg: #f0e8e8;
$form-data-title-color: #595959;

/* Buttons */
$btn-primary-bg: #23485d;
$btn-primary-color: #ffffff;
$btn-primary-hover-bg: #1b3a4d;

$btn-secondary-bg: #d2cccc;
$btn-secondary-color: #595959;
$btn-secondary-hover-bg: #b4afaf;
$btn-secondary-hover-color: #595959;

$btn-green-bg: #0fa759;
$btn-green-color: #ffffff;
$btn-green-hover-bg: #067a3f;
$btn-green-hover-color: #ffffff;

$btn-red-bg: #e72e2e;
$btn-red-color: #ffffff;
$btn-red-hover-bg: #bd4031;
$btn-red-hover-color: #ffffff;

$btn-yellow-bg: #fbae1f;
$btn-yellow-color: #ffffff;
$btn-yellow-hover-bg: #e19406;
$btn-yellow-hover-color: #ffffff;

$btn-blue-bg: #3e698e;
$btn-blue-color: #ffffff;
$btn-blue-hover-bg: #305474;
$btn-blue-hover-color: #ffffff;

$btn-gray-bg: #595959;
$btn-gray-color: #ffffff;
$btn-gray-hover-bg: #4b4b4b;
$btn-gray-hover-color: #ffffff;

$btn-add-to-cart-bg: #fee685;
$btn-add-to-cart-color: #756755;
$btn-add-to-cart-border: #ffcd07;
$btn-add-to-cart-hover-bg: #ffcd07;

$btn-remove-from-cart-bg: #ff8d7b;
$btn-remove-from-cart-color: #756755;
$btn-remove-from-cart-border: #e72e2e;
$btn-remove-from-cart-hover-bg: #e72e2e;

$btn-buy-now-bg: #ec5c0c;
$btn-buy-now-color: #756755;
$btn-buy-now-border: #c94c06;
$btn-buy-now-hover-bg: #c94c06;

$btn-gotopayment-bg: linear-gradient(180deg, #33b02f, #1f8f1b);
$btn-gotopayment-color: #ffffff;
$btn-gotopayment-border: #1a8d16;
$btn-gotopayment-hover-bg: linear-gradient(180deg, #1f8f1b, #12680f);
$btn-gotopayment-hover-border: #12680f;

$btn-cart-floating-bg: #e72e2e;
$btn-cart-floating-color: #ffffff;

/* Inputs */
$input-bg: #ffffff;
$input-color: #595959;
$input-border: #dbd3d3;

/* Badges */
$badge-primary-bg: #3e698e;
$badge-primary-color: #ffffff;

$badge-secondary-bg: #d2cccc;
$badge-secondary-color: #595959;

$badge-green-bg: #0fa759;
$badge-green-color: #ffffff;

$badge-red-bg: #e72e2e;
$badge-red-color: #ffffff;

$badge-yellow-bg: #fbae1f;
$badge-yellow-color: #ffffff;

$badge-blue-bg: #3e698e;
$badge-blue-color: #ffffff;

$badge-gray-bg: #595959;
$badge-gray-color: #ffffff;

$badge-purple-bg: #7851a9;
$badge-purple-color: #ffffff;

/* Cards */
$card-bg: #f0e8e8;
$card-footer-bg: #dbd3d3;

/* Tables */
$table-head-bg: $primary;
$table-head-color: #ffffff;
$table-body-bg: #ffffff;
$table-body-color: $primary;
$table-border: $primary;

/* Products - Gallery */
$product-gallery-anchor-bg: $primary;
$product-gallery-anchor-border: $primary;

/* Products - Cards */
$product-card-bg: #f0e8e8;
$product-card-footer-bg: #dbd3d3;
/* Products - Carousel */
$product-carousel-bg: #f0e8e8;
$product-carousel-footer-bg: #dbd3d3;
/* Products - Dropdown */
$product-dropdown-bg: #f0e8e8;
$product-dropdown-title-bg: $primary;
$product-dropdown-title-color: #f1f1f1;
/* Products - Course */
$product-course-bg: #f0e8e8;
$product-course-title-bg: $primary;
$product-course-title-color: $white;

/* Products - Cards */
$plan-default-bg: #f0e8e8;
$plan-default-footer-bg: #dbd3d3;

/* Auth Section */
$auth-form-container-bg: #f0e8e8;
$auth-form-container-color: #3e698e;
$auth-input-color: #595959;
$auth-input-bg: #ffffff;
$auth-input-border: #dbd3d3;

$auth-footer-bg: #2c536a;
$auth-footer-color: #f1f1f1;
$auth-footer-links-color: #f1f1f1;

/* Cart Section */
$cart-summary-bg: #f0e8e8;
$cart-summary-border: #dbd3d3;
$cart-address-bg: #ecdac2;
$cart-address-border: #dbd3d3;
$cart-items-border: #dbd3d3;

/* Checkout Section */
$checkout-summary-bg: #f0e8e8;
$checkout-summary-border: #dbd3d3;
$checkout-address-bg: #dbd3d3;
$checkout-address-border: #dbd3d3;
$checkout-items-border: #dbd3d3;

$checkout-form-bg: #f0e8e8;
$checkout-form-border: #dbd3d3;
$checkout-form-method-bg: #dbd3d3;

/* Payment Section */
$payment-items-border: #dbd3d3;

/* Purchases */
$purchase-item-bg: #efdfca;

/* Footer */
$footer-bg: #2c536a;
$footer-color: #f1f1f1;
$footer-links-color: #f1f1f1;
