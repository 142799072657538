@import '../../../assets/styles/config';

.expandable-container {
    display: flex;
    flex-direction: column;
    transition: max-height 0.5s linear 0, overflow 0.1 linear 0.5s;
    height: fit-content;
    max-height: 600px;
    &.expanded { animation: expanded 0.5s forwards; }
    &.collapsed { animation: collapsed 0.5s forwards; }
}

.verified-data {
    position: relative;
    .icon-verified {
        position: absolute;
    }
}

@keyframes expanded {
    0% {
        max-height: 0px;
        overflow: hidden;
    }
    99% {
        max-height: 600px;
    }
    100% {
        max-height: 600px;
        overflow: visible;
    }
}
@keyframes collapsed {
    0% {
        max-height: 600px;
        overflow: visible;
    }
    99% {
        max-height: 0;
    }
    100% {
        max-height: 0;
        overflow: hidden;
    }
}