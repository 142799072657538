@import '../../../assets/styles/config';

.plan-display {
    .plan {
        transition: all 0.5s;
        position: relative;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;

        .plan-header {
        }
        .plan-body {
            flex: 1;
            flex-shrink: 1;
        }
        .plan-footer {
        }
    }
}
