@import '../../../assets/styles/config';
@import '../../../assets/styles/theme';
@import '../../../assets/styles/buttons';

.featured-display {
    min-height: 300px;
    .product {
        min-width: 0;
        background-clip: border-box;
        border-radius: 0.25rem;
        min-width: 300px;
        width: 350px;
        display: flex;

        background-color: $product-card-bg;
        background-image: url('../../../assets/images/featured_product_bg.svg');
        background-position-x: right;
        background-position-y: 2rem;
        background-size: 70%;
        background-repeat: no-repeat;

        .product-supplier {
            text-align: left;
            width: 100%;
            padding: 0 0.3rem;
            span {
                padding: 0 0.25rem;
            }
        }

        .product-header {
            display: flex;
            flex-direction: column;
            align-items: space-between;
            justify-content: center;
            padding: 0.5rem 0.3rem;
            .product-thumbnail,
            .product-expand-button,
            .product-supplier,
            .product-viewing-progress {
                display: none;
            }
            .product-title {
                text-align: center;
                margin: 0;
            }
        }
        .product-body {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 0.25rem 1rem;
            flex: 2;
            .product-description {
                text-align: center;
                margin: 0;
                padding: 0.3rem;
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                color: $primary;
                .text-description {
                    margin: 0;
                    flex: 1;
                    flex-shrink: 1;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    text-shadow:
                        -1px -1px 0 $product-card-bg,
                        1px -1px 0 $product-card-bg,
                        -1px 1px 0 $product-card-bg,
                        1px 1px 0 $product-card-bg;
                }
                .product-supplier {
                    text-shadow:
                        -1px -1px 0 $product-card-bg,
                        1px -1px 0 $product-card-bg,
                        -1px 1px 0 $product-card-bg,
                        1px 1px 0 $product-card-bg;
                }
                .text-details {
                    display: none;
                }
                .payment-controls {
                    display: none;
                }
                .product-button-links {
                    display: none;
                }
            }
            .product-content {
                flex: 2;
                display: flex;
                flex-direction: column;
                width: 40%;
                .private-content {
                    flex: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .product-video {
            }
            .product-gallery {
                height: 200px;
            }
        }

        .product-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0.5rem 1rem;
            border-top: 1px solid rgba(8, 16, 43, 0.125);
            border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
            .payment-controls {
                display: flex;
                flex-direction: column;
                .product-price {
                    position: relative;
                    .price-symbol {
                        font-size: 60%;
                    }
                    .price-whole {
                    }
                    .price-fraction {
                        font-size: 60%;
                    }
                    &.strikethrough {
                        margin: 0 0.5rem;
                        position: relative;
                        font-size: 90%;
                        color: $dark-gray;
                        opacity: 0.5;
                        &::after {
                            top: 40%;
                        }
                    }
                }
                .payment-controls_buttons-container {
                    display: flex;
                    justify-content: space-evenly;
                    width: 100%;
                    padding: 0.25rem 0;
                    button span {
                        display: none;
                    }
                    .btn-payment {
                        flex: 1;
                        margin: 0 0.25rem;
                    }
                }
            }
            .product-button-links {
                display: flex;
                justify-content: center;
            }
        }
    }
}
