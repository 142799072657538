
.product-viewing-progress {
    display: flex;
    font-size: 0.7rem;
    padding: 0 0.25rem;
    min-width: 150px;
    .pvp-bar {
        width: 100px;
        background-color: #fdfdfd;
        display: flex;
        .bar {
            background-color: green;
        }
    }
    .pvp-value {
        padding: 0 0.25rem;
    }
}