@import '../../../../../../assets/styles/config';

.cancel-subscription-popup {
    .confirmation-code-input {
        height: 60px !important;
        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 8px;
    }

    .message-sent-container {
        border: dashed 1px $secondary;
        border-radius: 0.5rem;
        padding: 0.25rem 0.5rem;
    }
}
