@import "@app/assets/styles/config";

.payment-controls {
    .product-price_container {
        
    }
    .btn-payment,
    .btn-payment:hover,
    .btn-payment:active,
    .btn-payment:focus {
        line-height: 1;
        border-radius: 30px;
        color: $black;
    }

    .payment-controls_qtd-selector {
        height: 100%;
        input[type=button]{
            width: 20px;
            padding: 0;
        }
        .add-to-cart-btn-less {
            border-radius: 7px 0 0 7px;
        }
        .add-to-cart-btn-plus {
            border-radius: 0 7px 7px 0;
        }
        .add-to-cart-input-container{
            flex: 1;
            position: relative;
            display: flex;
            justify-content: start;
            align-items: center;
            &:before {
                content: "Qtd: ";
                left: 2%;
                padding: 0 0.25rem;
                color: #8d8d8d;
                display: flex;
                align-items: center;
                background-color: #dddddd;
                height: 100%;
                border: none;
            }
        }
        .add-to-cart-input {
            border-radius: 0 !important;
            width: 100%;
            padding-left: 1%;
            border: none;
        }
    }
}
