.plan-theme-selector {
    .color-picker {
        border-bottom: solid 1px #00000015;
        .label-color-id {
            font-size: 0.7rem;
        }
        .btn-open-picker {
            height: 20px;
            font-size: 0.7rem;
            border: solid 1px #9d8162;
        }
        .picker-popover {
            position: absolute;
            background-color: #f2f2f2;
            padding: 0.25rem;
            border-radius: 0.25rem;
            z-index: 1000;
            top: 0;
        }
    }
    .default-theme-selector {
        .default-theme {
            button {
                border: solid 1px #9d8162;
                height: 20px;
                width: 20px;
                box-shadow: 1px 1px 4px 0px #00000025;
            }
        }
    }
}
