@import '../../assets/styles/config';

.filter-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem 0 1rem;
    &.show-border {
        border-bottom: solid 2px $border-color;
    }

    .filter-title {
        width: 100%;
        font-size: 0.9rem;
        font-weight: 600;
        color: $dark-gray;
    }

    .filter-fields {
        flex: 1;
        fieldset {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 0.2rem 0.2rem 0;
            font-size: 0.9rem;
        }
    }

    .filter-tabs {
        font-size: 0.8rem;
        align-items: flex-end;
        margin: 0 0 -2px 1rem;
        flex-shrink: 0;
        .filter-tab {
            font-size: inherit;
            display: flex;
            align-items: flex-end;
            color: $primary;
            border-bottom: solid 1px transparent;
            padding: 3px;
            margin-left: -2px;
            min-width: 100px;
            height: 30px;
            justify-content: center;
            align-items: center;
            a,
            label {
                cursor: pointer;
                color: inherit;
                font-weight: 600;
                margin: 0;
                padding: 0;
                opacity: 0.7;
            }
            &.active,
            &:hover {
                border-bottom: solid 3px $dark-gray;
                padding-bottom: 2px;
                a,
                label {
                    font-weight: bold;
                    opacity: 1;
                }
            }
            &.disabled {
                a,
                label {
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }
    }
}
