@import '../../../assets/styles/config';

.cart-summary {
    .cart-values {
        background-color: $cart-summary-bg;
        border-bottom: none;
    }
    .cart-controls {
        border-left: solid 1px $cart-summary-border;
        background-color: $cart-summary-bg;
    }
}

.cart-items {
    border-bottom: solid 1px $cart-items-border;
    .cart-item {
        border-top: solid 1px $cart-items-border;

        .cart-item-thumb {
            img {
                max-width: 100px;
            }
        }
        .cart-item-details {
            .cart-item-name {
            }
            .cart-item-description {
            }
            .cart-item-qtd-select {
                max-width: 150px;
                cursor: pointer;
                box-shadow: 2px 2px 3px 0px rgb(0 0 0 / 40%);
            }
            .cart-item-qtd-input {
                max-width: 150px;
            }

            .cart-item-shipping-info {
                color: $dark-red;
                font-size: 0.6rem;
            }
        }
        .cart-item-price {
            background-color: #efdfca;
        }
        .priceExpiration {
            background-color: $regular-yellow;
            border: solid 1px $dark-yellow;
            font-size: 0.6rem;
            line-height: 0.65rem;
            padding: 0.2rem 0.4rem;
            border-radius: 0.2rem;
        }
        .priceHasChanged {
            background-color: $regular-red;
            border: solid 1px $dark-red;
            color: $dark-gray;
            font-size: 0.6rem;
            line-height: 0.65rem;
            padding: 0.2rem 0.4rem;
            border-radius: 0.2rem;
        }
    }
}

/* lg */
/*
@media (min-width: 992px) {

}
*/
/* md */
@media (max-width: 992px) {
    .cart-summary {
        .cart-values,
        .cart-controls {
            border-left: none;
            border-bottom: solid 1px $cart-summary-border;
        }
    }
    .cart-items {
        .cart-item {
            border-top: solid 2px $cart-items-border;
            margin-top: 0.5rem;

            .cart-item-price {
                margin-top: 0.5rem;
            }
        }
    }
}
