@import '../../../../../assets/styles/config';

.payment-summary {
    background-color: $cart-summary-bg;

    .payment-values {
        border-bottom: none;
        border-right: solid 1px $cart-summary-border;
    }
}
