$black: #08102b;
$white: #ffffff;

$dark-green: #067a3f;
$regular-green: #0fa759;
$light-green: #b7f5bd;
$hover-green: #0b8f4b;

$regular-blue: #2c536a;
$dark-blue: #223d54;
$light-blue: #6587a5;
$hover-blue: #223d54;

$dark-yellow: #ffcd07;
$regular-yellow: #fee685;
$light-yellow: #fff5c2;
$hover-yellow: #e1b60d;

$dark-red: #e72e2e;
$regular-red: #ff8d7b;
$light-red: #fdb8ae;
$hover-red: #bd4031;

$light-brown-border: #b08e7a;
$light-brown-text: #ecdcdc;

$dark-brown: #8b705f;
$regular-brown: #ae8c77;
$light-brown: #bea392;
$hover-brown: #8b705f;

$regular-gray: #8b8b8b;
$dark-gray: #595959;
$light-gray: #bdbdbd;
$hover-gray: #595959;

$dark-orange: #bd4a0a;
$regular-orange: #ec5c0c;
$light-orange: #f07d3d;
$hover-orange: #bd4a0a;

$dark-purple: #604187;
$regular-purple: #7851a9;
$light-purple: #9374ba;
$hover-purple: #604187;

$dark-primary: $dark-blue;
$regular-primary: $regular-blue;
$light-primary: $light-blue;
$hover-primary: $hover-blue;

$outer-shadow-color: rgba(0, 0, 0, 0.4);

@import './theme';
