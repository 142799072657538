.account-phone-number-confirmation {
    border: dashed 1px;
    border-radius: 10px;
    .confirmation-code-input {
        height: 60px !important;
        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 8px;
    }
}
