@import '../../../assets/styles/config';

.phone-confirmation {
    .confirmation-code-input {
        height: 60px !important;
        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 8px;
    }
}
