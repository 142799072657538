@import "../../../../../assets/styles/config";

.order-simulation-popup-form {
    h3 {
        font-size: 1rem;
        margin-top: 0.5rem;
    }
    table {
        font-size: 0.7rem;
        thead tr,
        tfoot tr {
            background-color: #E0BC88;
        }
        thead th,
        tfoot td {
            padding-top: 0;
            padding-bottom: 0;
        }
        .product-row {
            background-color: #DFCEB0;
        }
    }
    .table-container {
        max-height: 400px;
        overflow-y: auto;
        .table-split {
            thead tr,
            tfoot tr {
                background-color: #ec5c0c;
                td, th { 
                    color: #f2f2f2;
                    font-weight: 500;
                    font-family: 'Courier New', Courier, monospace;
                }
            }
        }
    }
}