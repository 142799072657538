.customer-pendencies-banner {
    color: #ffffff;
    border: dashed 1px #bd403183;
    background-color: #bd403183;
    padding: 0.1rem 0.5rem;
    border-radius: 8px;
    .banner-title {
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    p {
        font-size: 0.7rem;
    }
}
