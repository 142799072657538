@import '../../../../../assets/styles/config';

.subscription-config-form {
    background-color: $checkout-form-bg;
    .aditional-item-price_container {
        .aditional-item-price {
            position: relative;
            .price-symbol {
                font-size: 60%;
            }
            .price-whole {
            }
            .price-fraction {
                font-size: 60%;
            }
            .price-unit-label {
                font-size: 60%;
                align-self: flex-end;
            }
            &.strikethrough {
                margin: 0 0.5rem;
                position: relative;
                font-size: 90%;
                color: $dark-gray;
                opacity: 0.5;
                &::after {
                    top: 40%;
                }
            }
        }
    }
}
