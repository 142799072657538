.icon-list {
    max-height: 300px;
    overflow-y: auto;
    .icon {
        cursor: pointer;
        border: none;
        font-weight: normal;
        color: #595959;
        &:hover,
        &.active {
            background-color: #d0b08d;
            color: #f2f2f2;
        }
    }
}
