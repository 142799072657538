@import '../../../../../../assets/styles/config';

.form-content-details {
    h3 {
        background-color: $form-data-title-bg;
        color: $form-data-title-color;
        text-transform: uppercase;
        font-size: 0.9rem !important;
        line-height: 1rem;
        font-weight: 600;
        padding: 0.25rem;
    }
    .form-content-field {
        flex-direction: column;
    }
    .field-label {
        font-size: 0.8rem;
    }
    .field-value {
        font-size: 0.8rem;
        flex: 1;
        height: auto;
        padding: 0.2rem 0.6rem;
    }
}
