.navbar {
    padding: 5px;
    min-height: 60px;
    background-color: $navbar-bg;
    color: $navbar-color;
    background-image: url('../images/header_background.png');
    background-size: cover;
    .navbar-brand {
        padding: 0;
        flex-shrink: 0;
        img {
            height: 40px;
            max-height: 50px;
        }
    }
    h1 {
    }
    .corp-brand-container {
        img {
            height: 40px;
            max-height: 50px;
        }
    }

    .user-data {
        flex: 1;
        justify-content: flex-end;
        text-align: right;
        .user-icon {
            font-size: 1.5rem;
            color: $regular-blue;
            opacity: 0.45;
            cursor: pointer;
        }
        .user-drop-down-back {
            display: none;
            position: fixed;
            background-color: rgb(255 255 255 / 0%);
            left: 0;
            top: 0;
            flex: 2 1;
            z-index: 1200;
            width: 100vw;
            height: 100vh;
            &.visible {
                display: flex;
            }
        }
        .user-drop-down {
            display: none;
            position: absolute;
            right: 40px;
            top: 35px;
            flex: 2;
            z-index: 1201;
            text-align: right;
            padding: 0.5rem 1rem;
            color: $regular-blue;
            flex-wrap: wrap;
            @include outer-shadow();
            &.visible {
                display: flex;
            }

            .user-icon {
                font-size: 3rem;
            }
            .user-description {
                flex: 1;
                text-align: right;
                color: $dark-gray;
                label {
                    display: block;
                    text-align: right;
                    margin: 0.5rem 1rem;
                }
            }
            .user-logout-wrapper {
                width: 100%;
                border-top: solid 1px $dark-gray;
                padding-top: 1rem;
                margin-top: 1rem;
            }
        }
    }
}

.sub-navbar {
    padding: 5px;
    font-size: 0.8rem;
    border: none;
    display: flex;
    justify-content: flex-end;
    background-color: $sub-navbar-bg;
    color: $sub-navbar-color;
    a {
        padding: 0 1rem;
        color: inherit;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        &:hover,
        &.active {
            color: $sub-navbar-hover;
            text-decoration: underline;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
    .navbar {
        .navbar-brand {
            order: 1;
            img {
                max-width: 150px;
            }
        }
        h1 {
            order: 3;
            width: 100%;
            text-align: center;
        }
        .corp-brand-container {
            order: 2;
            img {
                max-width: 100px;
                height: auto;
            }
        }
    }
    .sub-navbar {
        justify-content: space-between;
        a {
            flex: 1;
            flex-direction: column;
            text-align: center;
            span {
                font-size: 0.7rem;
            }
        }
    }
}
