.taxation-rule {
    margin-bottom: 1rem;
    border-left: solid 2px #f0e8e8;
    border-bottom: solid 2px #f0e8e8;
    flex-shrink: 1;
    font-size: 0.8rem;

    button {
        border-radius: 2px;
        padding: 0.1rem 0.25rem;
        font-size: 0.7rem;
        margin: 0 0.25rem;
    }

    .rule-header,
    .rule-conditions {
        background-color: #f0e8e8;
        color: #595959;
        padding: 0 0.25rem;
    }
    .rule-header {
        justify-content: stretch;
        border-bottom: solid 1px #f0e8e8;
        label {
            flex: 1;
            font-weight: bold;
            display: flex;
            align-items: center;
            span {
                font-weight: normal;
                padding-left: 0.25rem;
            }
        }
    }
    .rule-conditions {
        label {
            font-weight: bold;
        }
    }
    .rule-split {
        .taxation-rule-split-table {
            font-size: 0.7rem;
            margin: 0;
            thead th {
                padding: 0.1rem 0.25rem;
            }
        }
    }
    .rule-subrules {
        h3 {
            text-align: center;
            padding: 0.1rem;
            margin: 0;
            font-size: 0.8rem !important;
            background-color: #ec5c0c;
            color: #fff;
            align-items: center;
            cursor: pointer;
            .counter {
                font-size: 0.6rem;
            }
        }
        .rule-subrule {
            padding: 0.25rem 0;
            .rule-subrule-order {
                font-family: 'Courier New', Courier, monospace;
                padding: 0.25rem;
                align-items: center;
                background-color: #eeeeee;
                font-weight: bold;
            }
            .taxation-rule {
                margin-bottom: 0;
            }
            &:hover {
                > .rule-subrule-order {
                    background-color: #d0b08d;
                    color: #f2f2f2;
                }
            }
        }
    }
}
