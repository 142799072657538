@import '../../../assets/styles/config';
@import '../../../assets/styles/theme';
@import '../../../assets/styles/buttons';

.mat-display {
    min-height: 250px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .product {
        width: 250px;
        background-color: $product-carousel-bg;
        background-clip: border-box;
        border-radius: 0;
        margin: 0 1px;
        min-height: 250px;

        .product-supplier {
            max-width: 250px;
        }

        .product-header {
            order: 2;
            display: flex;
            flex-direction: column;
            align-items: space-between;
            justify-content: center;
            padding: 0.5rem 0.3rem;
            .product-thumbnail,
            .product-expand-button,
            .product-viewing-progress {
                display: none;
            }
            .product-title {
                font-size: 0.8rem;
                text-align: center;
                margin: 0;
                padding: 0 0.3rem;
            }
        }
        .product-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem 0.25rem;
            order: 1;
            flex: 2;

            .product-description {
                display: none;
            }
            .product-content {
                flex: 2;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .product-gallery {
                    height: 100%;
                    .product-gallery_nav,
                    .product-gallery-anchors {
                        display: none;
                    }
                }
            }
            .product-video-container {
                width: 100%;
            }
        }
        .product-footer {
            order: 3;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            padding: 0.1rem 0.1rem;
            background-color: $product-carousel-footer-bg;
            border-top: 1px solid rgba(8, 16, 43, 0.125);
            border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
            padding: 0.25rem;
            .payment-controls {
                padding: 0.25rem 0;
                .product-price_container {
                    display: flex;
                    justify-content: space-evenly;
                    padding: 0 0.25rem;
                    .product-price {
                        font-size: 18px;
                        position: relative;
                        .price-symbol {
                            font-size: 60%;
                        }
                        .price-whole {
                            line-height: 18px;
                            margin: 0 0.25rem;
                        }
                        .price-fraction {
                            font-size: 60%;
                        }
                        &.strikethrough {
                            width: fit-content;
                            padding: 0 0.25rem;
                            font-size: 90%;
                            color: $dark-gray;
                            opacity: 0.3;
                        }
                    }
                }
                .payment-controls_qtd-selector {
                    margin-bottom: 0.25rem;
                    height: 20px;
                    padding: 0 0.25rem;
                    input {
                        height: 100%;
                        font-size: 0.8rem;
                        line-height: 0.8rem;
                        padding: 0;
                        font-weight: bold;
                    }
                    .add-to-cart-input-container {
                        &::before {
                            display: none;
                        }
                        input {
                            text-align: center;
                        }
                    }
                }
                .payment-controls_buttons-container {
                    display: flex;
                    justify-content: space-evenly;
                    margin: 0;
                    .btn-payment {
                        span {
                            display: none;
                        }
                        margin: 0 0.25rem;
                        padding: 0;
                    }
                }
            }
            .product-button-links {
                width: 100%;
                text-align: center;
                .btn-go-to-product {
                    padding: 0.375rem 0.5rem;
                }
            }
        }
    }
}
