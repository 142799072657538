@import '../../../../../assets/styles/config';

.checkout-form {
    background-color: $checkout-form-bg;

    .payment-method {
        @mixin list-element {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: stretch;
            margin: 0.25rem;
            padding: 0.5rem 0.25rem;
            input,
            svg,
            span {
                margin: 0 0.5rem;
            }
            span {
                flex: 2;
            }
        }
        .payment-method-option {
            @include list-element;
            background-color: $checkout-form-method-bg;
        }
        .payment-method-cards-list {
            padding: 0.25rem 0.5rem;
            .user-card {
                @include list-element;
                border: dashed 1px $checkout-form-method-bg;
            }
        }
    }
}
