@import "../../../../../assets/styles/config";

.new-rule-popup-form {
    h3 {
        font-size: 1rem;
        margin-top: 0.5rem;
    }
    table {
        font-size: 0.7rem;
        thead th {
            padding: 0;
        }
    }
}