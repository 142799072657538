@import '../../../../assets/styles/config';
@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/buttons';

.course-item-display {
    margin-bottom: 0.5rem;
    .product {
        width: 100%;

        background-color: $product-course-bg;
        background-clip: border-box;
        border-radius: 0.25rem;
        overflow: hidden;
        margin-bottom: 0.25rem;

        .product-header {
            display: flex;
            background-color: $product-course-title-bg;
            .product-thumbnail {
                width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.25rem;
                img {
                    height: 30px;
                }
            }
            .product-viewing-progress,
            .product-supplier {
                display: none;
            }
            .product-title {
                flex: 1;
                color: $product-course-title-color;
                text-align: left;
                padding: 0.5rem 0.3rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                a {
                    color: inherit;
                    flex: 1;
                }
            }
        }
        .product-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-flow: row-reverse;
            max-height: 0;
            transition: all 0.5s linear;
            overflow: hidden;

            .product-description {
                padding: 0.3rem;
                flex: 1;
                display: flex;
                flex-direction: column;
                height: 100%;
                .text-description {
                    font-weight: 600;
                }
                .text-details {
                    flex: 2;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #888;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
                .product-price_container {
                    align-items: center;
                    .product-price {
                        position: relative;
                        margin: 0.5rem;
                        font-size: 1.5rem;
                        .price-symbol {
                            font-size: 60%;
                        }
                        .price-whole {
                        }
                        .price-fraction {
                            font-size: 60%;
                        }
                        &.strikethrough {
                            color: $dark-gray;
                            opacity: 0.3;
                            font-size: 1rem;
                        }
                    }
                }
                .payment-controls {
                    display: flex;
                    flex-direction: column;
                    .payment-controls_buttons-container {
                        display: flex;
                        justify-content: space-evenly;
                        padding: 0.25rem 0;
                        .btn-payment {
                            flex: 0 1 40%;
                            width: auto;
                            margin: 0.5px;
                        }
                    }
                }
                .product-button-link {
                    width: 100%;
                    text-align: center;
                }
            }
            .product-content {
                flex: 2;
                height: 100%;
                width: 100%;
                flex-shrink: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 0.25rem;
                .private-content {
                    height: 100%;
                    width: 100%;
                }
                .product-video-container {
                    width: 80%;
                }
                .product-gallery {
                    min-height: 300px;
                }
                .product-video-player {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    flex: 2;
                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .product-footer {
            display: none;
        }
        &.expanded {
            .product-body {
                padding: 0.25rem;
                max-height: 1000px;
                height: auto;
            }
        }

        &.product-type-video {
            .product-header {
                .product-viewing-progress {
                    display: flex;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .course-display {
        .product {
            .payment-controls_buttons-container {
                flex-wrap: wrap !important;
                .btn-payment {
                    margin-bottom: 0.25rem !important;
                    flex: 0 1 100% !important;
                }
            }
        }
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
}
