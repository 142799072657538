.product-display {
    .product {
        transition: all 0.5s;
        position: relative;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;

        .product-thumbnail {
            display: none;
        }
        .product-supplier {
            font-size: 0.6rem;
            text-align: left;
            width: 100%;
            padding: 0.2rem 0;

            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            span {
                padding: 0 0.25rem;
                font-weight: bold;
            }
        }
    }
}
