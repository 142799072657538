.change-subscription-card-popup {
    .cards-list {
        flex-direction: column;
        max-height: 250px;
        overflow-y: auto;
        .card,
        .card label,
        .card input {
            cursor: pointer;
        }
    }
}
