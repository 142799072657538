@import './config';

.form {
    em {
        color: $dark-red;
        padding: 0 0.25rem;
    }
    .input-group {
        position: relative;
    }
    textarea {
        height: auto;
        resize: none;
    }
    .rsw-editor {
        background-color: #ffffff;
        border: solid 2px $border-color;
        border-radius: 2px 2px 0.5rem 0.5rem;
        [contenteditable='false'] {
            opacity: 0.3;
        }
        .rsw-toolbar {
            background-color: $primary;
            color: $white;
            padding: 0 0.25rem;
            button:not(.btn) {
                color: $white;
                border-radius: 5px;
                &:hover {
                    background-color: $dark-blue;
                    color: #f2f2f2;
                }
            }
        }
    }
    .selected-document-name {
        background-color: $primary;
        border: dashed 1px $dark-blue;
        margin: 0 0.25rem;
        font-size: 0.8rem;
        padding: 0.25rem;
        &::after {
            content: attr(data-format);
            background-color: $primary;
            margin: 0 0.25rem;
            padding: 0.1rem 0.25rem;
            color: #ffffff;
            border-radius: 0.25rem;
        }
    }
}
.field-group-title {
    position: relative;
    text-transform: uppercase;
    overflow: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &::after {
        content: '';
        background-color: $light-blue;
        width: 100%;
        height: 3px;
        flex-shrink: 1;
        margin-left: 10px;
    }
}
.field-label {
    font-size: 1rem;
    line-height: 1rem;
    display: inline-flex;
}

.content-form-small {
    .form {
        .field-label {
            font-size: 0.8rem;
            line-height: 0.8rem;
        }
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        input[type='datetime'],
        input[type='time'],
        input[type='date'],
        .field-value,
        textarea,
        select {
            padding: 0.2rem 0.4rem;
            height: 32px;
            font-size: 0.8rem;
            line-height: 0.8rem;
        }
    }
}

@media (max-width: 767px) {
    .field-group-title {
        white-space: unset;
        text-align: center;
        flex-wrap: wrap;
    }
}
