@import '../../../../assets/styles/config';
.plans-table {
    .prices {
        display: flex;
        flex-direction: column;
        .price {
            display: flex;
            background-color: $badge-secondary-bg;
            font-size: 0.6rem;
            margin-bottom: 2px;
            padding: 2px 4px;
            border-radius: 2px;
            .name {
                flex: 2;
            }
            .amount {
                flex: 1;
                text-align: right;
            }
            .installments {
                flex: 1;
                padding: 0 4px;
                text-align: right;
            }
        }
    }
    .row-products {
        border-bottom: solid 4px $table-head-bg;
        > td {
            padding: 0 0 0 0.5rem !important;
            background-color: $table-head-bg;
            table {
                margin: 0;
                thead th {
                    padding: 2px 4px;
                    font-size: 0.7rem;
                }
            }
        }
    }
}
