@import '../../../../../assets/styles/config';

.subscription-update-agreement {
    font-size: 0.7rem;
    .update-agreement-container {
        border: dashed 1px;
        background-color: $checkout-form-method-bg;
        .update-agreement-field-label {
            font-size: 0.8rem;
        }
    }
}
