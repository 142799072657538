@import '../../../../assets/styles/config';

table.sections-table tbody {
    .table-subsection-container {
        padding: 0 0 10px 15px;
        border-bottom: solid 4px $primary;
        .table-subsection {
            margin: 0;
            thead tr th {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .row-dragging {
        background-color: cyan;
        display: table;
        td {
            box-sizing: border-box;
        }
    }
    .text-url {
        font-size: 10px;
        opacity: 0.7;
    }
}
