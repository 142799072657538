@import './config';

.modal-content {
    .btn-info,
    .btn-modal-close
    {
        width: 37px;
        height: 37px;
        border-radius: 18px;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        box-shadow: none !important;
    }
    .btn-info,
    .btn-info:focus
    {
        background-color: $light-blue;
        color: $white;
        &:hover:not(:disabled) {
            background-color: $regular-blue;
            color: $white;
        }
    }

    .modal-tooltip {
        position: absolute;
        width: 50%;
        background-color: #FFF5C2;
        z-index: 1;
        right: 10px;
        padding: 1rem;
        border-radius: 1rem;
        border: solid 2px #FFCD07;    
    }

    .label-tip {
        .label-tip-icon {
            background-color: #E0BC88;
        }
    }
    
}